@import "styles/theme.scss";
.tablist {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: auto;
  max-height: 64px;
  font-size: $font-size-xs;
  color: $grey-light-color;
  border-top: 1px solid rgba($color: $grey-light-color, $alpha: 0.35);
}

.tab {
  --border-height: 2px;
  padding: 24px 58px;
  flex: 1 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% + 2px);
  color: inherit;

  svg {
    width: 18px;
    margin-right: 10px;
  }

  svg path {
    fill: currentColor;
  }

  &_selected {
    background-color: $light-blue-color;
    padding-top: calc(24px - var(--border-height));
    border-top: var(--border-height) solid;
    color: var(--primary-text-color);
    font-weight: $font-weight-semi-bold;
  }

  &:focus {
    outline: none;
  }
}
