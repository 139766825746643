@import "styles/theme.scss";
.fragments {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 27px);
  grid-template-rows: repeat(2, 26px);
  gap: 4px;

  &Letter {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
