@import "styles/theme.scss";
.modal {
  &Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 70px;
  }

  &Title {
    padding: 24px 0;

    text-align: center;
    font-weight: $font-weight-bold;
    font-size: $font-size-l;
  }

  &Description {
    padding-bottom: 36px;
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
  }

  &Buttons {
    display: grid;
    grid-template-columns: repeat(2, 264px);
    gap: 12px;
  }
}

.button {
  &Good {
    margin-top: 36px;
  }
}
