@import "styles/theme.scss";
.container {
  max-width: var(--max-width-container, 100%);
}

// stylelint-disable

.fl-ellipsis {
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  
  word-break: keep-all;

  -webkit-line-clamp: var(--line-clamp-num, 1);
  -webkit-box-orient: vertical;

  // hyphens: auto;
}

// stylelint-enable
