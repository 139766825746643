@import "styles/theme.scss";
.circular {
  display: block;
  width: 1.3em;
  height: 1.3em;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: currentColor;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 899, 200;
    stroke-dashoffset: -124px;
  }
}
