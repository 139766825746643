@import "styles/theme.scss";
.header {
  padding: 28px 32px;

  border-bottom: $border-primary;

  &Top {
    max-height: 158px;
    display: flex;
    align-items: center;
  }
}

.title {
  font-size: $font-size-m;
}
