@import "styles/theme.scss";
.label {

  --bottom-color: #{$select-border-color};
  --shadow-color: none;

  @include label;
  position: relative;

  &Error {
    --bottom-color: var(--wrong-color);
    --shadow-color: var(--wrong-color);
  }

  &Valid {
    --bottom-color: var(--primary-accent-color);
    --shadow-color: none;
  }
}

.option {
  font-family: $font-family;
  font-size: $font-size-xs;
}

.labelTitle {
  @include label-title;

  &Disabled {
    color: rgba($primary-color, 0.6);
  }
}

.errorMessage {
  @include error-message;
}

.description {
  
  @include description-message;
  opacity: 0.6;
}

.messageShow {
  @include message-show-animation;
}
