@import "styles/theme.scss";
.search {
  &Icon {
    padding-right: 5px;
  }

  &Container {
    max-width: 327px;
    padding-bottom: 6px;

    border-bottom: $border-primary;
  }

  &Input {
    position: relative;
    width: 100%;

    border-width: 0;
    appearance: none;

    padding: 0;

    background-color: $invisible-color;

    &::placeholder {
      right: 0;

      background-color: $invisible-color;
    }
  }

  &Button {
    font-family: inherit;
    font-size: $font-size-xxs;

    color: $grey-light-color;
  }
}
