@import "styles/theme.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 28px 36px;
}

.contentWrapper {
  display: grid;
  place-items: center;
  justify-items: start;
  column-gap: 24px;
  grid-template-areas:
    'headline headline'
    'text image';

  margin: auto;
  max-width: 804px;
  color: var(--primary-text-color);
}

.informationContainer {
  grid-area: text;
}

.headline {
  font-size: 40px;
  font-weight: $font-weight-bold;
  white-space: pre-line;
  margin-bottom: 25px;
  grid-area: headline;
}

.description {
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;

  & + & {
    margin-top: 20px;
  }
}

.a {
  font-weight: $font-weight-bold;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.imageWrapper {
  grid-area: image;
}
