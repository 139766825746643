@import "styles/theme.scss";
.button {
  --bg-clr: var(--primary-accent-color);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.667em 1em;
  width: 264px;
  background-color: var(--bg-clr);
  border-radius: $border-radius;
  line-height: 1.3;
  font-size: $font-size-s;
  text-align: center;
  color: var(--primary-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  transition: background-color 400ms, opacity 200ms;

  &SizeS {
    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-s;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--primary-hover-color);
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover[disabled],
  &:active[disabled] {
    background-color: var(--bg-clr);
  }
}

.outlined {
  --bg-clr: rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 1px currentColor;

  color: inherit;

  &:hover,
  &:focus,
  &:active {
    box-shadow: inset 0 0 0 1px currentColor;

    background-color: var(--bg-clr);
  }
}
