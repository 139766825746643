@import "styles/theme.scss";
.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($primary-color, 0.5);
  z-index: 1;
}
