@import "styles/theme.scss";
.item {
  width: 200px;

  & + & {
    margin-left: 12px;
  }
}

.sort {
  &Item {
    padding: 8px 12px;

    border-radius: $border-radius-s;

    background-color: $light-blue-color;

    font-size: $font-size-xxs;
    color: $grey-light-color;

    &_active {
      background-color: rgba(0, 0, 0, 0);

      color: $primary-color;
      box-shadow: inset 0 0 0 1px currentColor;

      .sortButton {
        font-weight: $font-weight-bold;
      }
    }
  }

  &Button {
    font-size: $font-size-xxs;
  }
}
