@import "styles/theme.scss";
:root {
  /* stylelint-disable */
  --_main-gradient: linear-gradient(
    306.87deg,
    #fff -4.02%,
    #ffedec 55.58%,
    #bde3ff 99.71%
  );
  /* stylelint-enable */
  --_primary-text-color: #003251;
  --_primary-accent-color: #00d1ff;
  --_primary-hover-color: #003251;
  --_primary-muted-color: #818ba7;
  --_primary-wrong-color: #ff1f26;
  --primary-success-color: #3f9142;
  --main-gradient: var(--_main-gradient);
  --primary-text-color: var(--_primary-text-color);
  --primary-accent-color: var(--_primary-accent-color);
  --primary-wrong-color: var(--_primary-wrong-color);
  --primary-hover-color: var(--_primary-hover-color);
  --default-right-color: #{$default-right-color};
  --wrong-color: #{$wrong-color};
  --grey-light-color: #{$grey-light-color};

  --input-radius: #{$input-radius};

  --background-color: #{$light-blue-color};

  --font-size-xs: #{$font-size-xs};
  --font-size-s: #{$font-size-s};
  --font-size-m: #{$font-size-m};

  --shadow-accordion: #{$shadow-main};

  --toastify-font-family: #{$font-family};
  --toastify-toast-width: 470px;
  --toastify-toast-min-height: 54px;
  --toastify-color-success: #e3f9e5;
  --toastify-color-error: #fee;
  --toastify-color-info: #e9fbff;
  --toastify-text-color-info: var(--primary-text-color);
  --toastify-text-color-success: var(--primary-text-color);
  --toastify-text-color-error: var(--primary-text-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

html,
body {
  position: relative;
  height: 100%;
  line-height: 1.3;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-style: normal;
  color: var(--primary-text-color);
}

input {
  user-select: text;
}

button,
textarea {
  cursor: pointer;
  border: 0;
  font-family: inherit;
  background-color: transparent;

  &:active,
  &:focus {
    outline: none;
  }

  &:disabled {
    -webkit-text-fill-color: currentcolor;
    opacity: 1;
    cursor: default;
  }
}

input {
  @include input-focus;
}

input:focus-visible {
  @include input-focus;
}

input:focus {
  @include input-focus;
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
}

ul {
  list-style: none;
}

a {
  text-decoration: none;

  color: inherit;
}

img:not([draggable='true']) {
  -webkit-user-drag: none;
}

.pointer {
  cursor: pointer;
}

.text {
  &_center {
    text-align: center;
  }
}

.full {
  &-width {
    width: 100%;
  }

  &-height {
    height: 100%;
  }
}

.flex {
  &-row {
    display: flex;
    flex-direction: row;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-align {
    &_start {
      align-items: flex-start;
    }

    &_center {
      align-items: center;
    }
  }

  &-justify {
    &_sb {
      justify-content: space-between;
    }

    &_center {
      justify-content: center;
    }

    &_end {
      justify-content: flex-end;
    }
  }
}

.link {
  cursor: pointer;
  color: $default-right-color;
}
/* stylelint-disable */

#__next {
  height: 100%;
}

/* stylelint-enable */
