@import "styles/theme.scss";
.segmentation {
  &Top {
    width: 100%;
    display: flex;
    padding-bottom: 36px;

    &_information {
      display: flex;
      flex-direction: column;
      max-width: 652px;
      padding-left: 24px;

      font-size: $font-size-xs;
    }

    &_title {
      padding: 10px 0 20px;

      font-weight: $font-weight-bold;
    }
  }

  &List {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 36px;
  }


  &Item {
    &_top {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
    }

    &_letter {
      padding-left: 10px;

      font-size: $font-size-l;
      font-weight: $font-weight-bold;
    }

    &_information {
      display: flex;
      flex-direction: column;

      font-size: $font-size-xxs;
    }

    &_title {
      padding-bottom: 15px;

      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
    }
  }
}
