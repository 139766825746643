@import "styles/theme.scss";
.root {
  :global(.Toastify__toast--info) {
    --color-status: var(--_primary-accent-color);
  }

  :global(.Toastify__toast--error) {
    --color-status: var(--primary-wrong-color);
  }

  :global(.Toastify__toast--success) {
    --color-status: var(--primary-success-color);
  }

  :global(.Toastify__toast-icon) {
    margin-inline-end: 2px;
  }
}

.body {
  color: var(--primary-text-color);
  align-items: flex-start;
  padding: 8px 14px;
}

.toast {
  padding: 0;
  border: 1px solid var(--color-status);
  border-radius: 6px;
}

.message {
  font-size: $font-size-xxs;
}

.alertText {
  font-size: 14px;
  font-weight: $font-weight-bold;
}

.close {
  font-weight: $font-weight-bold;
  font-size: $font-size-xxs;
  color: var(--color-status);
  padding-right: 18px;
}
