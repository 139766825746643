@import "styles/theme.scss";
.container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 32px;
}

.sort {
  &List {
    display: flex;
    align-items: center;
  }
}
