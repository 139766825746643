@import "styles/theme.scss";
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-weight: $font-weight-bold;
    font-size: var(--font-size, $font-size-m);
  }
}

.icon {
  margin-right: 12px;
}

.image {
  max-width: 590px;
  max-height: 27px;

  margin-right: 13px;
}
