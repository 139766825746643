@import "styles/theme.scss";
.container {
  font-size: $font-size-xs;
}

.title {
  font-size: $font-size-m;

  padding-bottom: 48px;
}

.indicator {
  text-transform: uppercase;
}

.indicator {
  padding: 24px 0;

  font-weight: $font-weight-bold;
}
