@import "styles/theme.scss";
.button {
  display: flex;
  align-items: center;

  font-size: $font-size-xxs;
  font-weight: 500;

  text-align: center;

  span {
    padding: 0 3px;

    line-height: 0.4;
  }
}

.icon {
  margin: 0 3px;

  &_mirror {
    transform: scaleY(-1);
  }
}
