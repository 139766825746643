@import "styles/theme.scss";
.content {
  position: relative;
  width: 100%;
  padding: 48px 0;
  max-width: 855px;

  background-color: white;

  box-shadow: $shadow-main;

  border-radius: $border-radius-s;
}
